import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { LocalstorageService } from '../services';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private localstorage: LocalstorageService, private router: Router) { }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

        const auth = this.localstorage.getItem('auth');
        const justRegistered = this.localstorage.getItem('REGISTERED_DATA');

        if (auth == null) {
            return true;
        }
        else {
            if (justRegistered)
                return true;
            else
                this.router.navigate(['/']);
                
            return false;
        }

    }
}
