import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@sass/environments/environment';

@Injectable()
export class UserService {

	constructor(private http: HttpClient) { }

	getUserById(userId: number) {
		return this.http.get(`${environment.API}user/${userId}`);
	}
}
