import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@sass/environments/environment';
import { ApiResponse } from '../models';

@Injectable({
  providedIn: 'root'
})
export class TipoInmuebleService {

  constructor(private http: HttpClient) { }

  getAll() {
    return this.http.get<ApiResponse>(`${environment.API}TipoInmueble`);
  }

  get(id) {
    return this.http.get<ApiResponse>(`${environment.API}TipoInmueble/${id}`);
  }
}
