import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiResponse } from '@app/core/models';
import { environment } from '@sass/environments/environment';

@Injectable()
export class PrivacyService {

  constructor(private http: HttpClient) { }

  getAll(): Observable<ApiResponse> {
      return this.http.get<ApiResponse>(`${environment.API}politicasdeprivacidad`);
  }

  find(id: number): Observable<ApiResponse> {
      return this.http.get<ApiResponse>(`${environment.API}politicasdeprivacidad/` + id);
  }
}
