import { Injectable } from '@angular/core';
import { element } from 'protractor';

@Injectable()
export class UtilsService {

	constructor() { }

	clearForm(form: any) {
		let inputs = Object.keys(form);

		inputs.forEach(element => {
			form[element] = '';
		});
	}

	setNullData(element: any) {

		if (typeof element === "object") {
			let getProperties = Object.keys(element);

			getProperties.forEach(item => {
				if (this.isEmpty(element[item]))
					element[item] = null;
			});
		}

		return element;
	}

	isEmpty(str: any) {
		return (str === "" || str === 0) ? true : false;
    }

    setUndefinedData(element: any) {

		if (typeof element === "object") {
			let getProperties = Object.keys(element);

			getProperties.forEach(item => {
				if (this.isEmpty(element[item]))
					element[item] = undefined;
			});
		}

		return element;
	}
}
