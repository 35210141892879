import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@sass/environments/environment';
import { ApiResponse } from '../models';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class InformacionVisitanteDocumentoService {

  constructor(
    private http: HttpClient
    , private commonService: CommonService
  ) { }

  getAll() {
    return this.http.get<ApiResponse>(`${environment.API}InformacionVisitanteDocumentos/Documentacion`);
  }
  get(id) {
    return this.http.get<ApiResponse>(`${environment.API}InformacionVisitanteDocumentos/Documentacion/${id}`);
  }

  create(data) {
    let formData = this.commonService.getFormData(data);
    return this.http.post<ApiResponse>(`${environment.API}InformacionVisitanteDocumentos/Documentacion`, formData);
  }

  update(data) {
    return this.http.put<ApiResponse>(`${environment.API}InformacionVisitanteDocumentos/Documentacion/${data.id}`, data);
  }

  delete(id) {
    return this.http.delete<ApiResponse>(`${environment.API}InformacionVisitanteDocumentos/Documentacion/${id}`);
  }
}
