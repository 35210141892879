import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { LOCALE_ID } from '@angular/core';

import { environment } from 'src/environments/environment';
import { AppComponent } from './app.component';
import { LoaderComponent } from './shared/components/customs/loader/loader.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoaderService } from './core/services';
import { LoaderInterceptor } from './core/interceptors/loader.interceptor';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { registerLocaleData } from '@angular/common';
import localePa from '@angular/common/locales/es-PA';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DateProxyPipe } from './date-proxy.pipe';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, '../../assets/i18n/', '.json');
}
registerLocaleData(localePa, 'es');

@NgModule({
  imports: [
    RouterModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireModule,
    AngularFireAuthModule,
    CoreModule,
    FormsModule,
    MatProgressSpinnerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    })
  ],
  declarations: [
    AppComponent,
    LoaderComponent,
  ],
  bootstrap: [
    AppComponent
  ],  
  providers: [
    LoaderService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'es' },
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ] 
})
export class AppModule { }
