import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@sass/environments/environment';
import { ApiResponse } from '../models';

@Injectable({
  providedIn: 'root'
})
export class InformacionVisitanteService {

  constructor(private http: HttpClient) { }

  getAll() {
    return this.http.get<ApiResponse>(`${environment.API}InformacionVisitante`);
  }

  getAllEmploymentStatus() {
    return this.http.get<ApiResponse>(`${environment.API}InformacionVisitante/GetAllEstatusLaboral`);
  }

  get(id) {
    return this.http.get<ApiResponse>(`${environment.API}InformacionVisitante/${id}`);
  }

  getByUserId(id) {
    return this.http.get<ApiResponse>(`${environment.API}InformacionVisitante/User/${id}`);
  }

  create(data) {
    return this.http.post<ApiResponse>(`${environment.API}InformacionVisitante`, data);
  }

  update(data) {
    return this.http.put<ApiResponse>(`${environment.API}InformacionVisitante/${data.id}`, data);
  }

  delete(id) {
    return this.http.delete<ApiResponse>(`${environment.API}InformacionVisitante/${id}`);
  }
}
