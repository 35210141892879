import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoaderService } from '../services/loader.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  instances: number = 0;
  constructor(public loaderService: LoaderService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.toLowerCase().includes('/publicidad/track')) {
      return next.handle(req);
    }

    this.instances++;
    if (this.instances === 1) {
      this.loaderService.show();
    }
    return next.handle(req).pipe(
      finalize(() => {
        this.instances--;
        if (this.instances <= 0) {
          this.instances = 0;
          this.loaderService.hide();
        }
      })
    );
  }
}
