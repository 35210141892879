import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@sass/environments/environment';
import { Observable } from 'rxjs';
import { ApiResponse } from '../models';

@Injectable()
export class StandService {

	constructor(private http: HttpClient) { }

	getStand(idStand: number): Promise<ApiResponse> {
		return this.http.get<ApiResponse>(`${environment.API}Stand/${idStand}`).toPromise();
	}

	getStandByExpositorId(id: number) {
		return this.http.get<ApiResponse>(`${environment.API}Stand/Expositor/${id}`);
	}

	getImages(idexpositor: number): Observable<ApiResponse> {
		return this.http.get<ApiResponse>(`${environment.API}Stand/expositor/${idexpositor}`);
	}
}
