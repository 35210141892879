import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SERVICES } from './services';
import { GUARDS } from './guards';
import { PIPES } from './pipes';

@NgModule({
	declarations: [],
	imports: [
		CommonModule
	],
	providers: [
		...SERVICES,
		...GUARDS,
		...PIPES
	]
})
export class CoreModule { }
