import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';

import { ApiResponse } from '@app/core/models';
import { environment } from '@sass/environments/environment';

@Injectable()
export class BusquedaGeneralService {
  pattern = new BehaviorSubject<string>('');

  constructor(private http: HttpClient) { }

  search(pattern: string, visitanteId: number, page: number, size: number): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(`${environment.API}Search/Generalsearch?pattern=${pattern}&visitanteId=${visitanteId}&size=${size}&page=${page}`);
  }

}
