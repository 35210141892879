import { Injectable } from '@angular/core';

@Injectable()
export class LocalstorageService {

	constructor() { }

	create(name, value) {
		window.localStorage.setItem(name, JSON.stringify(value));
	}

	getItem(value){
		return JSON.parse(window.localStorage.getItem(value));
	}

	read(name) {
		return JSON.parse(window.localStorage.getItem(name));
	}

	removeItem(name) {
		return window.localStorage.removeItem(name);
	}

	clearAll() {
		return window.localStorage.clear();
	}

	getVisitorId(){
		let visitorId = this.getItem('auth');
		return visitorId.id;
    }

    getRawItem(item) {
        return window.localStorage.getItem(item);
    }
}
