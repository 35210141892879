import { Injectable, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AngularFireAuth } from '@angular/fire/auth';
import { auth } from 'firebase';
import { Router } from '@angular/router';
import { GoogleUser } from '../models/google.interface';
import { LocalstorageService } from './localstorage.service';
import { environment } from '@sass/environments/environment';
import { CreateVisitanteModel } from '../models/register.interface';
import { Recovery } from '../models/recovery.interface';
import { ApiResponse } from '../models';
import { AuthOrigin } from '../models/auth-origin.enum';
import { Subject } from 'rxjs';
import { CommonService } from './common.service';
import { UserService } from '@app/core/services/user.service';

@Injectable()
export class AuthService {

	googleUser: GoogleUser;
	registerWithSocialNetworkSubject = new Subject<boolean>();
	
	constructor(
		private http: HttpClient,
		private ngzone: NgZone,
		private fireauth: AngularFireAuth,
		private router: Router,
		private localstorage: LocalstorageService,
		private userService: UserService,
		private commonService: CommonService) {
			this.setSocialNetworkRegisterTrue();
		}

	setSocialNetworkRegisterTrue(){
		this.registerWithSocialNetworkSubject.next(true);
	}

	setSocialNetworkRegisterFalse(){
		this.registerWithSocialNetworkSubject.next(false);
	}

	login(body) {
		return this.http.post(`${environment.API}Auth/Login`, body).toPromise();
	}

	private loginForSocial(body) {
		return this.http.post(`${environment.API}Auth/Login/SocialLogin`, body).toPromise();
	}

	registerVisitante(body: CreateVisitanteModel): Promise<any> {
		return this.http.post(`${environment.API}Visitante`, body).toPromise();
	}

	updateVisitante(id, data) {
		var formData = this.commonService.getFormData(data);

		return this.http.put(`${environment.API}Visitante/UpdateVisitanteInfo/${id}`, formData);
	}

	recoveryPassword(body: Recovery): Promise<any> {
		return this.http.post(`${environment.API}Visitante/RecoverPassword`, body).toPromise();
	}

	changePassword(body): Promise<any> {
		return this.http.post(`${environment.API}User/ChangePassword`, body).toPromise();
	}

	checkUserAndEmail(endpoint, search): Promise<any> {
		return this.http.post(`${environment.API}${endpoint}=${encodeURI(search)}`, {}).toPromise();
	}

	getInterest() {
		return this.http.get(`${environment.API}Interes`).toPromise();
	}

	getInterestByVisitante(id: number) {
		return this.http.get(`${environment.API}Visitante/GetIntereses/User/${id}`);
	}

	saveInterest(id, data) {
		return this.http.put(`${environment.API}Visitante/UpdateInteres/User/${id}`, { intereses: data }).toPromise();
	}	

	authenticateGoogleProvider(provider) {
		return this.fireauth.signInWithPopup(provider).then(response => {
			this.registerUserFromGoogle(response.additionalUserInfo.profile);
		});
	}

	authenticateFacebookProvider(provider) {
		return this.fireauth.signInWithPopup(provider).then(response => {
			console.log(response.additionalUserInfo);
			this.registerUserFromFacebook(response.additionalUserInfo.profile);
		});
	}

	googleRegistration() {
		return this.authenticateGoogleProvider(new auth.GoogleAuthProvider());
	}

	googleSignIn() {
		return this.fireauth.signInWithPopup(new auth.GoogleAuthProvider()).then(response => {
			let userProfile : any = response;
			let body = {
				fullname : userProfile.additionalUserInfo.profile.name,
				email: userProfile.additionalUserInfo.profile.email,
				origin: AuthOrigin.google								
			}
			return this.loginForSocial(body);
		});
	}

	
	facebookSignIn() {
		return this.fireauth.signInWithPopup(new auth.FacebookAuthProvider()).then(response => {
			let userProfile : any = response;
			let body = {
				fullname : userProfile.additionalUserInfo.profile.name,
				email: userProfile.additionalUserInfo.profile.email,
				origin: AuthOrigin.facebook								
			}
			return this.loginForSocial(body);
		});
	}

	googleOut() {
		return this.fireauth.signOut().then(() => {
			console.log('Signed out from Google');
		});		
	}

	getUser(id: number) {
		return this.http.get<ApiResponse>(`${environment.API}User/${id}`);
	}

	facebookRegistration() {
		return this.authenticateFacebookProvider(new auth.FacebookAuthProvider());
	}

	registerUserFromGoogle(googleProvider) {
		let googleUser: CreateVisitanteModel = {
			origin: AuthOrigin.google,
			country: '',
			user: {
				username: googleProvider.email,
				password: googleProvider.email,
				fullName: googleProvider.name,
				email: googleProvider.email,				
				phoneNumber: ''				
			}
		}

		this.registerVisitante(googleUser).then((response: ApiResponse) => {
			if (response.success) {
				googleUser['user']['id'] = response.result;
				this.localstorage.create('auth', googleUser);

			this.googleSignIn();

			}
		})
		.catch(error => {
			this.setSocialNetworkRegisterTrue();
		});
	}

	registerUserFromFacebook(facebookProvider) {
		let facebookUser: CreateVisitanteModel = {
			origin: AuthOrigin.facebook,
			country : '',
			user: {
				username: facebookProvider.email,
				password: facebookProvider.email,
				fullName: facebookProvider.name,
				email: facebookProvider.email,				
				phoneNumber: ''				
			}
		}

		this.registerVisitante(facebookUser).then((response: ApiResponse) => {
			if (response.success) {
				facebookUser['user']['id'] = response.result;
				this.localstorage.create('auth', facebookUser);

			
			this.facebookSignIn();
			}
		})
		.catch(error => {
			this.setSocialNetworkRegisterTrue();
		});
	}
}
