import { AuthService } from './auth.service';
import { BusquedaGeneralService } from './busquedageneral.service';
import { CommonService } from './common.service';
import { EspaciosPublicitariosService } from './espaciospublicitarios.service';
import { LocalstorageService } from './localstorage.service';
import { MediaService } from './media.service';
import { PrivacyService } from './privacy.service';
import { SponsorService } from './sponsor.service';
import { UserService } from './user.service';
import { UtilsService } from './utils.service';
import { VisitanteService } from './visitante.service';
import { StandService } from './stand.service';
import { InformacionVisitanteService } from './informacion-visitante.service';
import { TipoInmuebleService } from './tipo-inmueble.service';
import { ProvinciaService } from './provincia.service';
import { InformacionVisitanteDocumentoService } from './informacion-visitante-documento.service';
import { UserEmploymentService } from './user-employment.service';

export * from './auth.service';
export * from './busquedageneral.service';
export * from './common.service';
export * from './espaciospublicitarios.service';
export * from './loader.service';
export * from './localstorage.service';
export * from './media.service';
export * from './privacy.service';
export * from './sponsor.service';
export * from './user.service';
export * from './utils.service';
export * from './visitante.service';
export * from './stand.service';

export const SERVICES = [
	AuthService,
	BusquedaGeneralService,
	LocalstorageService,
	MediaService,
	PrivacyService,
	SponsorService,
	UserService,
	UtilsService,
	VisitanteService,
	EspaciosPublicitariosService,
	CommonService,
	StandService,
	InformacionVisitanteService,
	TipoInmuebleService,
	ProvinciaService,
	InformacionVisitanteDocumentoService,
	UserEmploymentService
];

