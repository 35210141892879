import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@sass/environments/environment';
import { ApiResponse } from '../models';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class UserEmploymentService {

  constructor(
    private http: HttpClient
    , private commonService: CommonService
  ) { }

  get(id) {
    return this.http.get<ApiResponse>(`${environment.API}User/${id}/Employment`);
  }

  update(id, data) {
    let formData = this.commonService.getFormData(data);
    return this.http.put<ApiResponse>(`${environment.API}User/${id}/Employment`, formData);
  }

  deleteDocumentByType(userId, typeId) {
    return this.http.delete(`${environment.API}User/${userId}/Employment/Document/Type/${typeId}`);
  }
}
