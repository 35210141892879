import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@sass/environments/environment';
import { ApiResponse } from '../models';

@Injectable({
  providedIn: 'root'
})
export class ProvinciaService {

  constructor(private http: HttpClient) { }

  getAll() {
    return this.http.get<ApiResponse>(`${environment.API}Provincia`);
  }

  get(id) {
    return this.http.get<ApiResponse>(`${environment.API}Provincia/${id}`);
  }
}
