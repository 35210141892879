import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { QuicklinkStrategy, QuicklinkModule } from 'ngx-quicklink';
import { AuthGuard, HomeGuard } from './core/guards';

const appRoute: Routes = [
   //{path: '', loadChildren: () => import('./modules/survey/survey.module').then(m => m.SurveyModule), canActivate: [AuthGuard] },
  { path: '', loadChildren: () => import('./modules/landing/landing.module').then(m => m.LandingModule), canActivate: [AuthGuard] },
  { path: 'home', loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule), canActivate: [HomeGuard] },
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  imports: [
    QuicklinkModule,
    RouterModule.forRoot(
      appRoute,
      {
        anchorScrolling: 'enabled',
        enableTracing: false,
        preloadingStrategy: PreloadAllModules,
        scrollPositionRestoration: 'enabled',
      }
    )
  ],
  exports: [
    RouterModule
  ]
})

export class AppRoutingModule { }
