import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ApiResponse } from '@app/core/models';
import { environment } from '@sass/environments/environment';

@Injectable()
export class EspaciosPublicitariosService {

  constructor(private http: HttpClient) { }

  search(seccion: string, espacio: string, expositorId: number, projectId: number): Observable<ApiResponse> {

    let params = '';
    if (expositorId) {
      params = params + `&expositorId=${expositorId}`;
    }

    if (projectId) {
      params = params + `&projectId=${projectId}`;
    }


    return this.http.get<ApiResponse>(`${environment.API}publicidad/getpublicidades?seccion=${seccion}&espacio=${espacio}${params}`);
  }

}
