import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class SponsorService {

	constructor(private http: HttpClient) { }

	public sponsors(): Observable<any> { // TODO: Change obs type to sponsor
		return this.http.get(`${environment.API}sponsor`);
	}

	public sponsorsByType(type: number): Observable<any> { // TODO: Change obs type to sponsor
		return this.http.get(`${environment.API}sponsor/tiposponsor/${type}`);
	}
}
