import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent {
	title = 'Feria Capac';

	constructor(
		private translate: TranslateService
	) {
		this.translate.setDefaultLang('es');
		this.translate.use(this.getLanguageInfo());
	}

	getLanguageInfo() {
		let language;
		if (!localStorage.getItem("LANG_DATA")) {
			language = localStorage.setItem("LANG_DATA", "es");
		} else {
			language = localStorage.getItem("LANG_DATA");
		}
		return language;
	}
}
