import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@sass/environments/environment';

@Injectable()
export class VisitanteService {

  constructor(
    private http: HttpClient
  ) { }

  setFavoriteProject(visitanteId: number, proyectoId: number) {
    return this.http.post(`${environment.API}visitante/registerfavoriteproject`, { visitanteId, proyectoId });
  }
}
