import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LocalstorageService } from '../services';

@Injectable()
export class HomeGuard implements CanActivate {

	constructor(private localstorage: LocalstorageService, private router: Router){ }

	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

		let auth = this.localstorage.getItem('auth');

		if(auth == null)
		{
			localStorage.setItem('redirectTo', state.url);
			this.router.navigate(['/']);
			return false;
		}
		else
			this.router.navigate(['/']);
			return true;
	}
}
