import { Injectable } from '@angular/core';

@Injectable()
export class CommonService {
  constructor(
  ) { }

  /**
   * Get form data from a model
   */
  getFormData(dataObject: any, formData?: FormData, parentKey?: string) {
    if (formData === undefined) {
      formData = new FormData();
    }
    for (const key in dataObject) {
      let formDataKey: string;
      if (parentKey !== undefined) {
        formDataKey = parentKey + '.' + key;
      } else {
        formDataKey = key;
      }

      if (dataObject[key] !== null) {
        if (typeof dataObject[key] === 'object') {
          switch (dataObject[key].constructor) {
            case Array: {
              for (const item in dataObject[key]) {
                formData.append(formDataKey + '[' + item + ']', dataObject[key][item]);
                this.getFormData(dataObject[key][item], formData, formDataKey + '[' + item + ']');
              }
              break;
            }
            case File: {
              formData.append(formDataKey, dataObject[key]);
              break;
            }
            default: {
              this.getFormData(dataObject[key], formData, formDataKey);
              break;
            }
          }
        } else {
          formData.append(formDataKey, dataObject[key]);
        }
      }
    }

    return formData;
  }

  isIOS() {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }
}
