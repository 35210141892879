// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  API: 'https://apicapac.azurewebsites.net/api/',
  // API: 'http://localhost:50763/api/',
  SITE_KEY: '6Leyv6sZAAAAAB9KK0eVOqIXTE2wHY7j6HVVxM_R',
  SECRET_KEY: '6Leyv6sZAAAAALlsezz9E-wxNCEaKd7rAOqwVuws',
  avatarApi: 'https://ui-avatars.com/api/?background=6d118b&color=fff&size=166&rounded=true&name=',
  firebase: {
    apiKey: 'AIzaSyDlabIwnU6ufDwgYH-Re4VNNtsf9AhIWh0',
    authDomain: 'feriacapac2020.firebaseapp.com',
    databaseURL: 'https://feriacapac2020.firebaseio.com',
    projectId: 'feriacapac2020',
    storageBucket: 'feriacapac2020.appspot.com',
    messagingSenderId: '774332914428',
    appId: '1:774332914428:web:e2be0f7ce284b2da701579',
    measurementId: 'G-4BBHC45NL3'
  },
  talkjs: {
    appId: 'guzDJzWJ',
    secretKey: 'sk_live_emvEeYwPafSG6sJQgZYG8Rj7',
    appName: 'capac'
  }
  , Map: {
    subscriptionKey: 'Xlc4_x1wsAFG8LDg0_1RXBEba1AoCrsFpRAwEepGfBY'
  }
  , Youtube: {
    BaseUrl: 'https://www.youtube.com/embed'
  },
  recaptcha: {
    siteKey: '6LfMUNcZAAAAAHsu0AlIu6mFEu2w0b0CuE9Nw93e'
  },
  adminUrl: 'https://feriacapac.azurewebsites.net'
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
