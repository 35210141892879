import { AuthGuard } from './auth.guard';
import { HomeGuard } from './home.guard';

export * from './auth.guard';
export * from './home.guard';

export const GUARDS = [
    AuthGuard,
    HomeGuard
];
